@font-face {
  font-family: "Bell MT Semi Bold";
  src: url("https://db.onlinewebfonts.com/t/bb25f84423b9515a1d475d02d2de6462.eot");
  src: url("https://db.onlinewebfonts.com/t/bb25f84423b9515a1d475d02d2de6462.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/bb25f84423b9515a1d475d02d2de6462.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/bb25f84423b9515a1d475d02d2de6462.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/bb25f84423b9515a1d475d02d2de6462.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/bb25f84423b9515a1d475d02d2de6462.svg#Bell MT Semi Bold")format("svg");
}

.App {
  text-align: center;
  background-color: #ebd5b4;
  min-height: 100vh;
  margin-top: 0;
}

.loader {
  color: #000;
}

/* formStyles.css */
.form-container {
  max-width: 80vw;
  width: 350px;
  margin-top: 15px;
  margin: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebd5b4;
}

.form-sameline{
  display: flex;
  flex-direction: row;
  width: 100%;
}

p {
  font-family: "Bell MT Semi Bold";
  font-size: 15px;
  font-weight: 400;
}

h1 {
  margin-top: 0;
  font-family: "Bell MT Semi Bold";
}

h2 {
  font-family: "Bell MT Semi Bold";
  font-weight: 600;
  font-size: 30px;
  color: #685e4d;
  margin: 15px;
}

h3 {
  font-family: "Bell MT Semi Bold";
  font-weight: 500;
  font-size: 20px;
  color: #92908d;
  margin: 0;
}

.checkout {
  margin: 10px;
}

.form-label {
  font-family: "Bell MT Semi Bold";
  margin: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.form-styling{
  flex: 1; /* Distribute available space equally among boxes */
  padding: 10px;
  margin: 10px;
  border: 1px solid #000;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.form-input {
  margin: 10px;
  max-width: 90%;
  font-size: 14px;
  color: #92908d;
  background-color: #Ccc3ad;
  height: 2rem;
  box-shadow: 5px 5px #92908d;
  border-width: 0;
  font-family: "Bell MT Semi Bold";
  font-size: 20px;
}

.email-input {
  max-width: 100%;
  font-size: 14px;
  color: #92908d;
  height: 2rem;
  box-shadow: 5px 5px #92908d;
  background-color: #Ccc3ad;
  border-width: 0;
  margin: 10px;
  font-family: "Bell MT Semi Bold";
  font-size: 20px;
}

.form-select {
  margin: 10px;
  max-width: 90%;
  font-size: 14px;
  color: #92908d;
  height: 2rem;
  box-shadow: 5px 5px #92908d;
  border-width: 0;
  background-color: #Ccc3ad;
  font-family: "Bell MT Semi Bold";
  font-size: 20px;
}

.form-input:focus,
.form-select:focus,
.email-input:focus {
  border-color: #fff;
  outline: none;
}

.a{
  color: #4a73be;
}

.form-button {
  padding: 9.1%;
  margin-top: 10px;
  background-image: url("./pbb/button1.PNG");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  border: none;
  cursor: pointer;
  font-size: 16px;
  min-width: 100%;
  max-height: 5%;
  box-shadow: 5px 5px #92908d;
  margin-bottom: 5vh;
}

header {
  margin-top: 0;
}

footer {
  margin-bottom: 10vh;
}

